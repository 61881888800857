import React, { useEffect, useMemo } from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import Modules from "../components/modules"

export const query = graphql`
  query PageBySlug($slug: String!) {
    sanityPageModulaire(slug: { current: { eq: $slug } }) {
      slug {
        current
      }
      seo {
        metaTitle
        metaDescription
        metaImage {
          asset {
            url
          }
        }
      }
      home
      title
      layout
      modules {
        ... on SanityTexteUI {
          _key
          _type
          columnSize
          texte: _rawTexte(resolveReferences: { maxDepth: 10 })
        }
        ... on SanityImageUI {
          _key
          _type
          image: _rawImage(resolveReferences: { maxDepth: 10 })
          link {
            label
            link
          }
        }
        ... on SanityTexteImageUI {
          _key
          _type
          texte: _rawTexte(resolveReferences: { maxDepth: 10 })
          image: _rawImage(resolveReferences: { maxDepth: 10 })
          link {
            label
            link
          }
        }
        ... on SanityImagesUI {
          _key
          _type
          images: _rawImages(resolveReferences: { maxDepth: 10 })
        }
        ... on SanityVideoUI {
          _key
          _type
          video {
            asset {
              url
            }
          }
        }
        ... on SanityCodeUI {
          _key
          _type
          title
          code
        }
        ... on SanityMarqueeUI {
          _key
          _type
          text
          foregroundColor
          backgroundColor
        }
        ... on SanityProductListUI {
          _key
          _type
          title
          products {
            _type
            slug {
              current
            }
            title
            scriptsSupporter
            trial {
              title
              typeface {
                base64
                mimeType
              }
            }
            variable
            typefaces {
              title
              typeface {
                mimeType
                base64
                openTypeMetadata
              }
            }
          }
        }
      }
    }
  }
`

const PageModulaire = ({ data }) => {
  const { slug, seo, title, home, modules, layout } = data.sanityPageModulaire
  // console.log(modules)

  // useEffect(() => {}, [])

  return (
    <div className="page">
      {seo && (
        <Seo
          pageTitle={seo.metaTitle}
          pageDescription={seo.metaDescription}
          pageImage={seo.metaImage?.asset.url}
          template={`template-page page-${slug.current} ${
            home ? "page-home" : "page-deep"
          }`}
          page={true}
        />
      )}

      <div className="p-md py-md md:py-xl ">
        {!home && (
          <>
            {layout === "centered" ? (
              <div className="row">
                <div className="col-md-1 hidden-sm"></div>
                <div className="col-xs">
                  <h1 className="mb-md">{title}</h1>
                </div>
                <div className="col-md-1 hidden-sm"></div>
              </div>
            ) : (
              <h1 className="mb-md">{title}</h1>
            )}
          </>
        )}

        {modules && <Modules input={modules} layout={layout} />}
      </div>
    </div>
  )
}

export default PageModulaire
