import React from "react"
import SanityTexte from "../SanityTexte"
import SanityImage from "../SanityImage"

const TexteImageUI = ({ input }) => {
  return (
    <section className="module-texte-image-ui mb-md text-lg col-span-2 ">
      <div className="row">
        <div className="col-md col-xs-12">
          <div className="mb-md md:mb-0">
            <SanityTexte input={input.texte} />
            {input.link && (
              <a href={input.link.link} className="" target="_blank">
                {input.link.label}
              </a>
            )}
          </div>
        </div>
        <div className="col-md col-xs-12">
          {input.link && (
            <a href={input.link.link} className="" target="_blank">
              <SanityImage input={input.image} />
            </a>
          )}
          {!input.link && <SanityImage input={input.image} />}
        </div>
      </div>
    </section>
  )
}

export default TexteImageUI
